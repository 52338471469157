import {
    doc,
    collection,
    setDoc,
    addDoc,
    getDoc,
    serverTimestamp,
    updateDoc,
    getDocs,
    deleteDoc,
    limit,
    orderBy,
    query,
    startAfter,
} from "firebase/firestore";
import { db } from "./firebase";

export async function createNewUserDocument(user) {
    const userRef = doc(db, "users", user.uid);
    const userDocRef = doc(db, "users", user.uid);

    // Check if a document for the user already exists
    const userDoc = await getDoc(userDocRef);
    if (!userDoc.exists()) {

        await setDoc(userRef, {
            userId: user.uid,
            email: user.email,
            username: user.email,
            createdAt: serverTimestamp(),
            projects: [],
        });

        const projectRef = await addDoc(collection(db, "projects"), {
            api_key: null,
            name: "Personal",
            projectId: user.uid,
            plan: "starter",
            users: [user.uid],
            createdAt: serverTimestamp(),
        });

        await updateDoc(userRef, {
            projects: [projectRef.id],
            selectedProjectId: projectRef.id,
        });

        await setDoc(doc(db, "projects", projectRef.id, "teamMembers", user.uid), {
            email: user.email,
            role: "owner",
        });
    }
}

export async function getSelectedProjectId(userId) {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);
    if (!userDoc.exists()) {
        return "FZZaFgM5NOx8yO1kmmsI";
    }
    return userDoc.data().selectedProjectId;
}

export async function updateSelectedProjectId(userId, projectId) {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { selectedProjectId: projectId });

    return true;
}

export async function getProjectAPIKeys(projectId) {
    const querySnapshot = await getDocs(collection(db, "projects", projectId, "apiKeys"));
    const apiKeys = [];
    querySnapshot.forEach((doc) => {
        apiKeys.push({ ...doc.data(), id: doc.id });
    });

    apiKeys.sort((a, b) => {
        return a.createdAt - b.createdAt;
    });

    return apiKeys;
}

export async function createProjectAPIKey(projectId, name) {

    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let secret = 'sk-';
    for (let i = 0; i < 48; i++) {
        secret += characters.charAt(Math.floor(Math.random() * characters.length));
    }

    const apiKeyRef = await addDoc(collection(db, "projects", projectId, "apiKeys"), {
        name: name,
        secret: secret,
        createdAt: serverTimestamp(),
        lastUsedAt: null,
    });

    await setDoc(doc(db, "apiKeys", apiKeyRef.id), {
        secret: secret,
        projectId: projectId,
    });

    // return secret and id of document
    return { secret: secret, id: apiKeyRef.id };
}

export async function getProjectInfo(projectId) {
    const projectRef = doc(db, "projects", projectId);
    const projectDoc = await getDoc(projectRef);

    return { name: projectDoc.data().name };
}

export async function updateProjectInfo(projectId, name) {
    const projectRef = doc(db, "projects", projectId);
    await updateDoc(projectRef, { name: name });

    return true;
}


export async function getProfileInfo(userId) {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);

    return { name: userDoc.data().username };
}

export async function updateProfileInfo(userId, name) {
    const userRef = doc(db, "users", userId);
    await updateDoc(userRef, { username: name });

    return true;
}

export async function getUserProjects(userId) {
    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);

    // check which projectId is the same as selectedProjectId and set that one to selected in a json object
    const projects = [];

    const selectedProjectId = userDoc.data().selectedProjectId;
    const userProjects = userDoc.data().projects;

    for (let i = 0; i < userProjects.length; i++) {
        const projectRef = doc(db, "projects", userProjects[i]);
        const projectDoc = await getDoc(projectRef);
        projects.push({
            id: projectDoc.id,
            name: projectDoc.data().name,
            isSelected: projectDoc.id === selectedProjectId,
        });
    }

    return projects;
}

export async function updateProjectAPIKey(projectId, keyId, name) {
    const keyRef = doc(db, "projects", projectId, "apiKeys", keyId);

    await updateDoc(keyRef, { name: name });

    return true;
}

export async function deleteProjectAPIKey(projectId, keyId) {
    const projectKeyRef = doc(db, "projects", projectId, "apiKeys", keyId);

    const keyRef = doc(db, "apiKeys", keyId);

    await deleteDoc(projectKeyRef);
    await deleteDoc(keyRef);

    return true;
}

export async function getTeamMembers(projectId) {
    const querySnapshot = await getDocs(collection(db, "projects", projectId, "teamMembers"));
    const teamMembers = [];
    querySnapshot.forEach((doc) => {
        teamMembers.push({ ...doc.data(), userId: doc.id });
    });
    console.log("teamMembers: ", teamMembers);
    return teamMembers;
}

export async function addTeamMember(projectId, email, role) {
    const usersRef = collection(db, "users");
    const querySnapshot = await getDocs(usersRef);
    let userId = null;
    querySnapshot.forEach((doc) => {
        if (doc.data().email === email) {
            userId = doc.id;
        }
    });

    if (userId === null) {
        return { success: false, addedUser: null };
    }

    const teamMemberRef = doc(db, "projects", projectId, "teamMembers", userId);
    await setDoc(teamMemberRef, { email: email, role: role });


    const userRef = doc(db, "users", userId);
    const userDoc = await getDoc(userRef);
    const userProjects = userDoc.data().projects;

    if (!userProjects.includes(projectId)) {
        userProjects.push(projectId);
        await updateDoc(userRef, { projects: userProjects });
    }

    return { success: true, addedUser: { userId: userId, email: email, role: role } };
}







export async function getSessionsHistory(projectId, lastVisible = null, limitCount = 20) {
    let q;
    if (lastVisible) {
        q = query(collection(db, "projects", projectId, "sessions"), orderBy("createdAt", "desc"), startAfter(lastVisible), limit(limitCount));
    } else {
        q = query(collection(db, "projects", projectId, "sessions"), orderBy("createdAt", "desc"), limit(limitCount));
    }
    const querySnapshot = await getDocs(q);
    const sessions = [];
    let lastVisibleDoc = null;

    let index = 0;

    querySnapshot.forEach((doc) => {
        sessions.push({ ...doc.data(), id: doc.id });
        if (index === querySnapshot.docs.length - 1) {
            lastVisibleDoc = doc;
        }
        index++;
    });


    return { sessions, lastVisibleDoc };
}
