import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home/Home";
import Overview from "./pages/Docs/Overview/Overview";
import HowItWorks from "./pages/Docs/HowItWorks/HowItWorks";
import './App.css';
import './css/components/button.css'
import './css/components/footer.css'
import './css/components/focusView.css'
import './css/components/verticalBox.css'
import './css/components/textfield.css'
import './css/components/banner.css'
import './css/components/infoHover.css'
import './css/components/menu.css'
import './css/components/table.css'
import './css/components/sidebar.css'
import './css/components/formField.css'
import './css/components/toast.css'

import SignUp from "./pages/Auth/SignUp";
import Login from "./pages/Auth/Login";
import ResetPassword from "./pages/Auth/ResetPassword";

import Dashboard from "./pages/Dashboard/Dashboard";
import APIKeys from "./pages/Dashboard/APIKeys/APIKeys";
import Project from "./pages/Dashboard/Project/Project";
import Team from "./pages/Dashboard/Team/Team";
import Profile from "./pages/Dashboard/Profile/Profile";
import Billing from "./pages/Dashboard/Billing/Billing";
import SessionsHistory from "./pages/Dashboard/SessionsHistory/SessionsHistory";
import PricingPlans from "./components/pricingPlans/PricingPlans";
import Landing from "./Landing";


function App() {
  return (
    <Router>
      <Routes>
      <Route path="/" element={<Landing />} />

        {/*
        <Route path="/" element={<HomePage />} />
        <Route path="/pricing" element={<Pricing />} />
        */}


        {/* Auth Routes */}
        {/*
        <Route path="/signup" element={<SignUp />} />
        <Route path="/login" element={<Login />} />
        <Route path="/resetpassword" element={<ResetPassword />} />
        */}



        {/* Dashboard Routes */}
        
        {/*
        <Route path="/dashboard" element={<Profile />} />
        <Route path="/dashboard/overview" element={<Dashboard />} />
        <Route path="/dashboard/sessions-history" element={<SessionsHistory />} />
        <Route path="/dashboard/organization" element={<Project />} />
        <Route path="/dashboard/team" element={<Team />} />
        <Route path="/dashboard/api-keys" element={<APIKeys />} />
        <Route path="/dashboard/profile" element={<Profile />} />
        <Route path="/dashboard/billing" element={<Billing />} />
        */}

      </Routes>
    </Router>
  );
}

function Pricing() {
  return (
    <div>
      <header class="navbar relative z-[500] container mx-auto px-4 sm:px-6 lg:px-8 flex items-center h-24 text-black font-medium text-sm" data-menu-container="">
        <nav class="relative z-50 flex">
          <a href="/" aria-label="Canopy Labs">
            <span className="font-semi text-lg">Canopy Labs</span>
          </a>
        </nav>

        <button data-menu-toggle="" aria-expanded="false" class="menu-toggle block ml-auto lg:hidden relative z-50">
          <span class="sr-only">Open main menu</span>

          <svg aria-hidden="true" role="img" class="" viewBox="0 0 24 24" className="pointer-events: none; width: 24px; height: 24px; " fill="currentColor">
            <g buffered-rendering="static">
              <path d="M21.815 4.519a1.5 1.5 0 00-1.5-1.5H3.685a1.5 1.5 0 000 3h16.63a1.5 1.5 0 001.5-1.5zM21.815 19.481a1.5 1.5 0 00-1.5-1.5H3.685a1.5 1.5 0 000 3h16.63a1.5 1.5 0 001.5-1.5zM21.815 12a1.5 1.5 0 00-1.5-1.5H8.564a1.5 1.5 0 000 3h11.751a1.5 1.5 0 001.5-1.5z"></path>
            </g>
          </svg>
        </button>

        <nav aria-label="Main menu" data-menu="" class="hidden grid lg:flex items-center justify-center content-center gap-y-4 flex-1 z-40 fixed inset-0 lg:static bg-gray-900 lg:bg-transparent text-base sm:text-sm">
          <dl class="hidden shrink-0 lg:flex group relative ml-auto mt-3 mb-0 px-3 pt-2 pb-5 cursor-pointer">
            <dt class="flex items-center justify-items-center whitespace-nowrap transition-colors group-hover:text-violet-400 group-focus-within-text-violet-400">
              <a href="/" class="text-center mx-auto lg:mx-3 transition-colors hover:text-violet-400">
                Home
              </a>
            </dt>
          </dl>
          <a href="https://canopyai.mintlify.app/" class="text-center mx-auto lg:mx-3 transition-colors hover:text-violet-400">
            Docs
          </a>
          <a href="/pricing" class="text-center mx-auto lg:ml-3 lg:mr-auto transition-colors hover:text-violet-400">
            Pricing
          </a>

          {/* <a href="/login" class="text-base sm:text-sm btn btn-translucent">
              Log In
            </a> */}
          <a href="/signup" class="text-base sm:text-sm lg:mx-3 btn btn-purple">
            Sign Up
          </a>

        </nav>
      </header>
      <div className="p-8">
        <PricingPlans />
      </div>
    </div>
  )
}

export default App;
