import React from 'react';
import DashboardSidebar from '../Components/DashboardSidebar';
import Header from '../../../components/header/Header';
import MainHeading from '../../../components/headings/MainHeading';
import { useAuth } from '../../../auth';
import { useState, useEffect } from 'react';
import { getSessionsHistory } from '../../../firestore';
import Button from '../../../components/button/Button'

function SessionsHistory() {
    const { authUser, isLoading } = useAuth();

    const [sessions, setSessions] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [hasMore, setHasMore] = useState(false);

    const fetchSessions = async (lastDoc) => {
        const { sessions, lastVisibleDoc } = await getSessionsHistory(authUser.selectedProjectId, lastDoc, 20);
        console.log("lastVisibleDoc: ", lastVisibleDoc);
        setLastVisible(lastVisibleDoc);
        setSessions(prevSessions => lastDoc ? [...prevSessions, ...sessions] : sessions);
        setHasMore(sessions.length > 0);
    };

    useEffect(() => {
        if (!isLoading && authUser) {
            fetchSessions();
        }
    }, [authUser, isLoading]);

    const handleNextPage = () => {
        if (lastVisible) {
            fetchSessions(lastVisible);
            console.log("Fetching next page")
            console.log("sessions: ", sessions)
        }
    };


    if (isLoading) {
        return <div>Loading...</div>;
    }

    const convertToDate = (timestamp) => {
        if (!timestamp) return 'Never';

        let date;
        if (timestamp instanceof Date) {
            date = timestamp;
        } else if (timestamp && typeof timestamp.toDate === 'function') {
            date = timestamp.toDate();
        } else {
            console.error('Invalid date format');
            return 'Error';
        }

        const formatTime = (value, unit) => {
            return `${value} ${unit}${value > 1 ? 's' : ''} ago`;
        };

        const now = new Date();
        const diffInSeconds = Math.floor((now - date) / 1000);

        if (diffInSeconds < 60) {
            return formatTime(diffInSeconds, 'second');
        } else if (diffInSeconds < 3600) {
            return formatTime(Math.floor(diffInSeconds / 60), 'minute');
        } else if (diffInSeconds < 86400) {
            return formatTime(Math.floor(diffInSeconds / 3600), 'hour');
        } else {
            return date.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' });
        }
    };



    return (
        <div className='m-0 h-full min-h-screen'>
            <Header />
            <div className='flex flex-wrap'>
                <DashboardSidebar selectedItem={'Sessions History'} />
                <div className='flex-grow p-10'>
                    <MainHeading title='Sessions History' />
                    <div className='overflow-x-auto'>
                        <table className="w-full divide-y divide-gray-300 border border-b-0">
                            <thead className="bg-gray-50">
                                <tr className='font-medium text-gray-500 uppercase text-sm'>
                                    <th className="py-3.5 pl-4 pr-3 text-left sm:pl-6">Started</th>
                                    <th className="px-3 py-3.5 text-left">Total Time</th>
                                    <th className="px-3 py-3.5 text-left">Total Messages</th>
                                    <th className="py-3.5 pl-3 pr-4 sm:pr-6"></th>
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-200 bg-white">
                                {sessions.map((apiKey) => (
                                    <tr key={apiKey.id}>
                                        <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm sm:w-auto sm:max-w-none sm:pl-6">
                                            {convertToDate(apiKey.createdAt)}
                                        </td>
                                        <td className="hidden px-3 py-4 text-sm lg:table-cell">
                                            {Math.round((apiKey.durationLength / 60) * 100) / 100} minutes
                                        </td>
                                        <td className="px-3 py-4 text-sm">
                                            {apiKey.messageThreadLength}
                                        </td>
                                        <td className="py-4 pl-3 pr-4 text-sm font-medium sm:pr-6">
                                            <div className="flex justify-end">
                                                <div className="flex items-center relative">
                                                    <span className="flex justify-end">
                                                        <div onClick={() => { }} className='mr-3 cursor-pointer hover:text-gray-700'>
                                                            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="2" stroke="currentColor" className="w-5 h-5">
                                                                <path strokeLinecap="round" strokeLinejoin="round" d="m8.25 4.5 7.5 7.5-7.5 7.5" />
                                                            </svg>
                                                        </div>
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        <div className="w-full flex items-center justify-between border border-t-none border-gray-200 bg-white px-4 py-3 sm:px-6 rounded-b-md">
                            <div className="flex-1 justify-between items-center hidden sm:flex">
                                <span className="block text-sm font-normal text-gray-700"></span>
                                <div className="flex space-x-3">
                                    {hasMore &&
                                        <Button
                                            size="small"
                                            type="black"
                                            text="Load More Sessions"
                                            onPress={() => handleNextPage()}
                                            isAsync={true}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SessionsHistory