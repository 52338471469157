import React, { useState, useEffect, useRef } from 'react';

function MainHeading({
    title = null,
    className = ''
}) {
    return (
        <div
            className={title ? `text-2xl font-md ${className}` : "hidden"}
        >
            {title}
        </div>
    )
}

export default MainHeading